<template>
  <div class="row justify-content-center">
    <div class="col-10">
      <h2>{{ 'services_title' | trans }}</h2>
    </div>
    <vote-panel />
    <revive-service />
    <level-service />
    <pet-service />
    <mount-service />
    <rename-service />
    <customize-service />
  </div>
</template>
<script>
import PetService from "./PetService.vue"
import LevelService from './LevelService.vue'
import ReviveService from './ReviveService.vue'
import RenameService from './RenameService.vue'
import MountService from './MountService.vue'
import { Common } from "@/common"
import VotePanel from "@/components/modules/services/VotePanel.vue"
import CustomizeService from "@/components/modules/services/CustomizeService.vue"

export default {

    metaInfo () {
        return Common.getMetaInfo(this.$route.name)
    },

    components: {
        VotePanel,
        LevelService,
        ReviveService,
        PetService,
        RenameService,
        MountService,
        CustomizeService
    },
    created () {
        this.loadScripts()
    },
    beforeDestroy () {
        this.unloadScripts()
    },
    methods: {
        async loadScripts () {
            const scriptPath = (process.env.NODE_ENV === `development`) ? `http://localhost:3080` : `https://ftp.murlocvillage.com`
            window.CONTENT_PATH = `${scriptPath}/${this.$store.getters.getUser.mirror_token}/modelviewer/live/`
            let loadedCount = 0
            const scripts = [
                { id: `jquery-js`, src: `https://code.jquery.com/jquery-3.5.1.min.js` },
                {
                    id: `viewer-js`,
                    src: `${scriptPath}/${this.$store.getters.getUser.mirror_token}/modelviewer/live/viewer/viewer.min.js`
                }
            ]
            const _this = this
            scripts.forEach(script => {
                const s = document.createElement(`script`)
                s.type = `text/javascript`
                s.id = script.id
                s.src = script.src
                s.onload = async () => {
                    loadedCount++
                    if (loadedCount === scripts.length) {
                        // When all scripts are loaded, import the components
                        const CustomizeServiceModule = await import(`@/components/modules/services/CustomizeService.vue`)
                        const MountServiceModule = await import(`./MountService.vue`)

                        this.$set(this.$options.components, `MountService`, MountServiceModule.default)
                        this.$set(this.$options.components, `CustomizeService`, CustomizeServiceModule.default)

                        await _this.$store.dispatch(`setViewerScriptsLoaded`, true)
                    }
                }

                document.head.appendChild(s)
            })
        },
        unloadScripts () {
            // List of script IDs that you've added dynamically
            const scriptIds = [`viewer`, `jquery`] // Modify this list based on the scripts you've added

            scriptIds.forEach(id => {
                const scriptElement = document.getElementById(id)
                if (scriptElement) {
                    document.head.removeChild(scriptElement)
                }
            })
        }
    }
}
</script>
